import React from "react"
import Layout from "../components/layout"
import DadPhotos from '../../static/dad-photos/dad-photo.jpg'
import DadTree from '../../static/dad-photos/image8.jpeg'
import DadWedding from '../../static/dad-photos/dad-wedding.jpg'
import Nkumbe from '../../static/dad-photos/nkumbe_tonio.jpg'
import Plaque from '../../static/dad-photos/bench-plaque.jpg'
import video1 from '../video/VID-20210116-WA0002.mp4'
import video2 from '../video/VID-20210116-WA0003.mp4'
import "../styles/global.css"

export default function Home() {
  return (
    <Layout>
      <div style={{ position: 'relative', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `linear-gradient(180deg, rgb(218 240 255 / 100%), rgb(202 226 231 / 0%), rgb(202 226 231 / 0%)), url(${Nkumbe})`, minHeight: '45rem', width: '100vw' }}>
      <div className='home-section' style={{ alignItems: 'flex-start', backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '45rem' }}>
       <div style={{ width: '50%', padding: '3rem 2rem' }}>
       <h1>Nkumbe memorial bench</h1>
      <p>Kruger was Maree's favourite place. A bench was erected at the Nkumbe lookout point in his honour, just ahead of the first anniversary of his passing.</p>
      <img src={Plaque} style={{ width: '7rem' }} />
      </div>
      <div style={{ padding: '4rem 1rem' }}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d927539.449510246!2d30.98129339091282!3d-24.754297504476074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1edd592eb74f4977%3A0x9ebb96450b61f97b!2sNkumbe%20View%20Site!5e0!3m2!1sen!2sza!4v1633178481859!5m2!1sen!2sza" width="300" height="150" style={{ border: "0" }} allowfullscreen="" loading="lazy"></iframe>
        {/*<img src={DadWedding} style={{ height: 'fit-content', width: '35cd %', padding: '5px' }} alt='Maree' className='memorial-photos' />*/}
        </div>
      </div>
      <div class="caption">View from Nkumbe | Photo credit: Jose Antonio Gonzalez Rodriguez</div>
      </div>
      <div className='home-section' >
        <div className='home-tile image'>
          <div>
            <img src={DadWedding} alt='Maree'className='memorial-photos' style={{ marginBottom: '0.5rem', width: '100%' }} />
          </div>
          <video src={video1} width='50%' controls />
          <video src={video2} width='50%' controls />
          <div>
            <img src={DadTree} alt='Maree' className='memorial-photos' style={{ marginTop: '0.5rem', marginBottom: '10px', width: '100%' }} />
          </div>
      </div>
       <div className='home-tile poem' >

          <h3>When Great Trees Fall</h3>
          <p><em>Maya Angelou</em></p>
          <p>
            When great trees fall,<br />
            rocks on distant hills shudder,<br />
            lions hunker down<br />
            in tall grasses,<br />
            and even elephants<br />
            lumber after safety.<br />
          </p>
          <p>
            When great trees fall<br />
            in forests,<br />
            small things recoil into silence,<br />
            their senses<br />
            eroded beyond fear.<br />
          </p>
          <p>
            When great souls die,<br />
            the air around us becomes<br />
            light, rare, sterile.<br />
            We breathe, briefly.<br />
            Our eyes, briefly,<br />
            see with<br />
            a hurtful clarity.<br />
            Our memory, suddenly sharpened,<br />
            examines,<br />
            gnaws on kind words<br />
            unsaid,<br />
            promised walks<br />
            never taken.<br />
          </p>
          <p>
            Great souls die and<br />
            our reality, bound to<br />
            them, takes leave of us.<br />
            Our souls,<br />
            dependent upon their<br />
            nurture,<br />
            now shrink, wizened.<br />
            Our minds, formed<br />
            and informed by their<br />
            radiance, fall away.<br />
            We are not so much maddened<br />
            as reduced to the unutterable ignorance of<br />
            dark, cold<br />
            caves.<br />
          </p>
          <p>
            And when great souls die,<br />
            after a period peace blooms,<br />
            slowly and always<br />
            irregularly. Spaces fill<br />
            with a kind of<br />
            soothing electric vibration.<br />
            Our senses, restored, never<br />
            to be the same, whisper to us.<br />
            They existed. They existed.<br />
            We can be. Be and be<br />
            better. For they existed.<br />
          </p>
        </div>
      </div>
    </Layout>
  )
}