import React, { Fragment } from "react"
import { Link } from "gatsby"
import logo from '../images/signature-white.svg'
import "../styles/global.css"
import "typeface-noto-serif"
import "typeface-bitter"
import "typeface-quicksand"

export default function Layout({ children }) {
  return (
    <Fragment>
      <div className="header">
        <div className='header-img'/>
        <div className='black-bg'></div>
        <div className='header-text'>
        <hr />
          <img src={logo} alt='Maree signature' className='logo' />
          <div style={{ position: 'absolute', top: '9.2rem', paddingLeft: '20px' }}>
            <Link className='header-link' to="/">Home</Link>
            <Link className='header-link' to="/photos/">Photography</Link>
            <Link className='header-link' to="/memorial/">Memorial</Link>
          </div>
        </div>
      </div>
      <div className="body">
        {children}
      </div>
    </Fragment>
  )
}